import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"
import { getNumberOfGuestCount } from "../../middleware/backent_helper";

const guestComponent = () => {
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );
  const [GuestCount, setGuestCount] = useState([]);
  useEffect(() => {
  getNumberOfGuestCount(dbName)
  .then(response => {
    
    setGuestCount(response.data.data);

  })
  .catch(error => {
    console.error(error);
  });

}, []);
  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5>No of Guests</h5>

              </div>
            </Col>
            <Col xs="5" className="align-self-end">

              <h4 className="text-start">{GuestCount[0]?.TotalGuestCount || 0}</h4>
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="1">
              <div className="avatar-sm rounded-circle bg-primary align-self-center " style={{ marginTop: '-26px' }}>
                <span className="avatar-title rounded-circle bg-light" >
                  <i className="bx bx-group font-size-24 text-primary"></i>
                </span>
              </div>

            </Col>

            <Col sm="11">
              <div className="pt-4">
                <Row>
                  <Col xs="4" xl="3" sm="3">

                    <p className="text-muted ">Male</p>
                    <h5 className="font-size-15 mb-0">{GuestCount[0]?.MaleCount || 0}</h5>
                  </Col>
                  <Col xs="4" xl="4" sm="3">

                    <p className="text-muted ">Female</p>
                    <h5 className="font-size-15 mb-0">{GuestCount[0]?.FemaleCount || 0}</h5>
                  </Col>
                  <Col xs="3" xl="4" sm="3">

                    <p className="text-muted ">Child</p>
                    <h5 className="font-size-15 mb-0">{GuestCount[0]?.ChildCount || 0}</h5>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    to=""
                    className="btn btn-primary  btn-sm"
                  >
                    View Reports <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default guestComponent
