import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
} from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb";
//i18n
import { withTranslation } from "react-i18next"
import { getAllRoomCheckinDetails, roomTypeRead, getUpdateRecord, getAllRoomStatus, getReservationNotify, getAllRoomTypes } from "../../../middleware/backent_helper";
import BlockModal from "components/Booking/block";
import CheckOutModal from "../../../components/Checkout/checkOutModal";
import UnBlockModal from "components/Booking/unblock";
import UpdateModal from "components/Booking/update";
import MaintenanceHistory from "components/History/maintenanceHistory";
import RoomHistory from "components/History/roomHistory";
import ReservationNotify from "components/Booking/reservation";

import { useNavigate } from 'react-router-dom';
import Reservation from "pages/Reservation/reservation";


const CheckIn = props => {

  document.title = "StayPro | Check In/Check Out"

  const navigate = useNavigate();
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );
  const [showBlockModal, setBlockModal] = useState(false);
  const [ismodalopen, setIsmodalopen] = useState(false);
  const [showUnBlockModal, setUnBlockModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showMaintenanceHistoryModal, setShowMaintenanceHistoryModal] = useState(false);
  const [showRoomHistory, setShowRoomHistory] = useState(false);
  const [RoomsTypeList, setRoomsTypeList] = useState([]);
  const [RoomsList, setRoomsList] = useState([]);
  const [updateModalText, setUpdateModalText] = useState("");
  const [textUpdate, setTextUpdate] = useState("Not Ready");
  const [roomNo, setRoomNo] = useState();
  const [roomId, setRoomId] = useState();
  const [bookingId, setBookingId] = useState(null);
  const [historyId, setHistoryId] = useState(null);
  const [updateBookingId, setUpdateBookingId] = useState(null);
  const [cleaningStatus, setCleaningStatus] = useState('');
  const [roomStatus, setRoomStatus] = useState(0);
  const [selectedRoomId, setSelectedRoomId] = useState(null)
  const [notifyReservation, setNotifyReservation] = useState([])
  const [roomNoSearch, setRoomNoSearch] = useState('');

  const [cleaningStatusMap, setCleaningStatusMap] = useState({});

  useEffect(() => {
    getAll();
  }, []);

  const getAll = () => {
    // Get Room-Types
    getAllRoomTypes(dbName)
      .then((res) => {

        setRoomsTypeList(res.data.data);
      });
    // Get Rooms
    getAllRoomCheckinDetails(hid, dbName)
      .then((res) => {
        setRoomsList(res.data.data);

        // Get cleaning status for each room with RoomStatusId == 3


        const cleaningStatusPromises = res.data.data
          .filter(room => room.RoomStatusId === 3)
          .map(room => getUpdateRecord(hid, dbName, room.bookingId)
            .then(response => ({ bookingId: room.bookingId, cleaningStatus: response.data.data.Clean_status }))
          );
        Promise.all(cleaningStatusPromises)
          .then(cleaningStatusResults => {
            const cleaningStatusMap = cleaningStatusResults.reduce((map, result) => {
              map[result.bookingId] = result.cleaningStatus;
              return map;
            }, {});
            setCleaningStatusMap(cleaningStatusMap);
          })
          .catch(error => console.error(error));

      });
    // Room Status list
    getAllRoomStatus(dbName)
      .then((res) => {
        setRoomStatus(res.data.data);
      });
  }

  const [isRoomCheckedOut, setIsRoomCheckedOut] = useState(false);

  useEffect(() => {
    if (isRoomCheckedOut) {
      getUpdateRecord(hid, dbName, updateBookingId)
        .then(response => {
          setCleaningStatus(response.data.data.Clean_status);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [isRoomCheckedOut, updateBookingId]);


  useEffect(() => {

    getReservationNotify(hid, dbName)
      .then(response => {
        setNotifyReservation(response.data.data);

      })
      .catch(error => {
        console.error(error);
      });

  }, []);


  const handleBlockClick = (roomId) => {
    setBlockModal(true);
    setSelectedRoomId(roomId);
  };

  const handleBlockSubmit = (data) => {
    setBlockModal(false);
    setSelectedRoomId(null);
  };

  const handleHistoryClick = (data) => {

  };

  const handleUnBlockClick = (roomId) => {
    setSelectedRoomId(roomId);
    setUnBlockModal(true);
  };

  const handleUnBlockSubmit = (data) => {
    setUnBlockModal(false);
  };

  const handleOpenModal = (bookingId) => {
    setBookingId(bookingId);
    setIsmodalopen(true);
  };

  const handleMainHistoryModal = () => {
    setShowMaintenanceHistoryModal(true)
  };
  const handleRoomHistoryModal = (id) => {
    setHistoryId(id)
    setShowRoomHistory(true)
  };

  const handleUpdateModal = (roomNo, bookingId) => {
    setRoomNo(roomNo);
    setUpdateBookingId(bookingId);
    setShowUpdateModal(true);
  };

  const handleUpdateSubmit = (updateBookingId) => {
    if (updateBookingId) {
      getUpdateRecord(hid, dbName, updateBookingId)
        .then(response => {
          setCleaningStatus(response.data.data.Clean_status);
          console.log(cleaningStatus)
          getAll();
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const toggleModal = () => setIsmodalopen(false);

  const handleCheckInClick = (roomId) => {
    navigate(`/booking_CheckIn?roomId=${roomId}`);
  };


  return (
    <React.Fragment>
      <BlockModal
        show={showBlockModal}
        handleSubmit={handleBlockSubmit}
        onCloseClick={() => { setBlockModal(false), getAll(), setSelectedRoomId(null) }}
        roomId={selectedRoomId}
      />
      <UnBlockModal
        show={showUnBlockModal}
        handleSubmit={handleUnBlockSubmit}
        onCloseClick={() => { setUnBlockModal(false), getAll(), setSelectedRoomId(null) }}
        roomId={selectedRoomId}
        bookingId={bookingId}
      />
      <CheckOutModal ismodalopen={ismodalopen} toggleModal={toggleModal} bookingId={bookingId} />
      <UpdateModal
        show={showUpdateModal}
        onCloseClick={() => { getAll(), setShowUpdateModal(false) }}
        updateBookingId={updateBookingId}
        roomNo={roomNo}
        onStatus={handleUpdateSubmit}
      />
      <MaintenanceHistory
        show={showMaintenanceHistoryModal}
        onCloseClick={() => setShowMaintenanceHistoryModal(false)}
      />
      <RoomHistory
        show={showRoomHistory}
        bookingId={historyId}
        onCloseClick={() => setShowRoomHistory(false)}
      />


      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Bookings" breadcrumbItem="Check In - Check Out" />


          <Card>
            <CardBody>
              <div className="row align-items-center">
                <div className="col-12 col-md-3 col-xl-3 col-lg-3">
               <input
                    type="text"
                    placeholder= "Search by Room No."
                    value={roomNoSearch}
                    onChange={(e) => setRoomNoSearch(e.target.value)}
                    className="form-control  search-input"
                  />
                </div>
                <div className="col-12 col-md-9 col-xl-9 d-flex flex-wrap justify-content-end">
                  <span className=" mx-2 my-2 px-3 py-2"
                    style={{
                      fontWeight: "600", color: "#fff", backgroundColor: roomStatus.length > 0 ? roomStatus[0]?.StatusColor : '#ddd',
                    }}
                  >
                    <i className="fas fa-bed"></i> Vacant: {roomStatus.length > 0 && roomStatus[0]?.RoomCount}
                  </span>
                  <span className=" mx-2 my-2 px-3 py-2"
                    style={{
                      fontWeight: "600", color: "#fff", backgroundColor: roomStatus.length > 1 ? roomStatus[1]?.StatusColor : '#ddd',
                    }}
                  >
                    <i className="fas fa-lock"></i> Occupied: {roomStatus.length > 0 && roomStatus[1]?.RoomCount}
                  </span>
                  <span className=" mx-2 my-2 px-3 py-2"
                    style={{
                      fontWeight: "600", color: "#fff", backgroundColor: roomStatus.length > 2 ? roomStatus[2]?.StatusColor : '#ddd',
                    }}
                  >
                    <i className="fas fa-broom"></i> House Keeping: {roomStatus.length > 2 && roomStatus[2]?.RoomCount}
                  </span>
                  <span className=" mx-2 my-2 px-3 py-2"
                    style={{
                      fontWeight: "600", color: "#2d2626", backgroundColor: roomStatus.length > 3 ? roomStatus[3]?.StatusColor : '#ddd',
                    }}
                  >
                    <i className="fas fa-tools"></i> Maintenance: {roomStatus.length > 3 && roomStatus[3]?.RoomCount}
                  </span>
                </div>
              </div>
            </CardBody>
          </Card>

          {notifyReservation?.length > 0 && <ReservationNotify notifyReservation={notifyReservation} />}
          {RoomsTypeList && RoomsTypeList.map((roomType, typeIndex) => (
            <Card key={typeIndex} className="my-3">
              <CardBody>
                <h5>{roomType.title.toUpperCase()} - {roomType.normalTariff}</h5>
                <Row>
                  {RoomsList && RoomsList.filter(rooms => rooms.title === roomType.title &&
                    rooms.roomNo.toString().includes(roomNoSearch)).map((rooms, roomIndex) => (
                      <Col xs="12" sm="6" md="4" lg="3" key={roomIndex}>
                        <Card
                          className="text-center"
                          style={{
                            backgroundColor: rooms.colorCode,
                            color: 'white',
                            maxWidth: '300px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    
                          }}
                        >

                          <div>
                            <h1 className="mt-3" style={{ color: rooms.RoomStatusId === 4 ? '#2d2626' : 'white' }}>{rooms.roomNo}</h1>
                            <h5 style={{ color: rooms.RoomStatusId === 4 ? '#2d2626' : 'white' }}>
                              {rooms.RoomStatusId == 1 || rooms.RoomStatusId == 3 || rooms.RoomStatusId == 4
                                ? rooms.statusName
                                : (
                                  <>
                                    {rooms.guestName || rooms.statusName}
                                    <h6 className="p-2"><i className="fas fa-solid fa-phone phone-color"></i> {rooms.mobileNo || ''}  <i className="fas fa-location-arrow"></i> {rooms.city || ''}</h6>
                                  </>
                                )
                              }
                            </h5>
                            <h6>
                              {
                                rooms.RoomStatusId == 3 ? (
                                  cleaningStatusMap[rooms.bookingId] === "Not Ready" ? 'Not Ready' :
                                    cleaningStatusMap[rooms.bookingId] === 'Cleaning' ? 'Cleaning' :
                                      cleaningStatusMap[rooms.bookingId] === 'Cleaned' ? 'Cleaned' :
                                        ''
                                ) : ''
                              }
                            </h6>
                            {rooms.isCheckedIn === 1 ? (
                              <>
                                <div className="Checkout_button p-2">
                                  <button className="btn btn-light w-sm" onClick={() => handleOpenModal(rooms.bookingId)} style={{ marginRight: '16px' }}>
                                    <i className="fas fa-sign-out-alt" ></i> Check-out
                                  </button>
                                  <button className="btn btn-light w-sm" onClick={() => handleRoomHistoryModal(rooms.bookingId)}>
                                    <i className="fas fa-history" ></i> History
                                  </button>
                                </div>
                              </>
                            ) : rooms.RoomStatusId === 4 ? (
                              <>

                                <div className="Maintanance_button p-2 ">
                                  <button className="btn btn-light  w-sm Maintanance_buttons" onClick={() => handleUnBlockClick(rooms.roomId)} >
                                    <i className="far fa-edit" ></i> UnBlock
                                  </button>
                                  <button className="btn btn-light w-sm ">
                                    <i className="fas fa-history "></i> History
                                  </button>
                                </div>
                              </>

                            ) : rooms.RoomStatusId === 3 ? (
                              <>
                                <div className="hosuse_keeping_button p-2"  >
                                  <button className="btn btn-light w-sm" onClick={() => handleUpdateModal(rooms.roomNo, rooms.bookingId)} style={{ marginRight: '16px' }}>
                                    <i className="far fa-edit" ></i> Update
                                  </button>
                                  <button className="btn btn-light w-sm" onClick={() => handleMainHistoryModal(rooms.bookingId)}>
                                    <i className="fas fa-history" ></i> History
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="checkin_button p-2">
                                  <button className="btn btn-light w-sm" onClick={() => handleCheckInClick(rooms.roomId)} style={{ marginRight: '16px' }}>
                                    <i className="fa fa-check" ></i> Check In
                                  </button>
                                  <button className="btn btn-light w-sm" onClick={() => handleBlockClick(rooms.roomId)} >
                                    <i className="fa fa-ban" ></i> Block
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </CardBody>
            </Card>
          ))}
        </Container>
      </div>
    </React.Fragment >
  )
}

export default withTranslation()(CheckIn)