const Description = (cell) => {
    return cell.value ? cell.value : '';
};
const Dates = (cell) => {
    return cell.value ? cell.value : '';
};
const Income = (cell) => {
    return cell.value ? cell.value : '';
};
const Expenses = (cell) => {
    return cell.value ? cell.value : '';
};
const Balance = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    Description,
    Dates,
    Income,
    Expenses,
    Balance
};