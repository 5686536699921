import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
  Label,
  Input,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import { addPrintConfig, getPrintConfig, getSign } from "../../../middleware/backent_helper";
import messages from "../../../utils/constants/messages";
import Breadcrumbs from "components/Common/Breadcrumb";
import AlertModal from "components/Common/alertModal";
import ErrorAlertModal from "components/Common/errorAlertModal";
import { useFormik } from "formik";
import $ from "jquery";
import "summernote/dist/summernote-bs4.css";
import "summernote";

const PrintConfig = (props) => {
  document.title = "General | Print Config";

  const navigate = useNavigate();
  const [alertModal, setAlertModal] = useState(false);
  const [errorAlertModal, setErrorAlertModal] = useState(false);
  const [response, setResponse] = useState(false);
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem("hotelId");
    return storedHotelId ? storedHotelId.replace(/"/g, "") : "";
  });
  const [dbName, setDbName] = useState(localStorage.getItem("dbName"));
  const [masterName, setMasterName] = useState("Setting");
  const [printConfig, setPrintConfig] = useState({});
  const [sign, setSign] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showSign, setgetSign] = useState({});
  const [selectedImage, setSelectedImage] = useState("");
  const [images, setImages] = useState([]);

  useEffect(() => {
    getPrintConfig(dbName).then((res) => {
      setPrintConfig(res.data.data[0] || {});
      $(".declaration_summernote").summernote("code", res.data.data[0].declaration || "");
    });
  }, [dbName]);

  useEffect(() => {
    getSign(hid, dbName).then((res) => {
      setgetSign(res.data.data[0]);
    });
  }, [hid, dbName]);

  const handleFileChange = (file) => {
    setSign(file);
    const fileUrl = URL.createObjectURL(file);
    setImagePreview(fileUrl);
  };

  useEffect(() => {
    $(".declaration_summernote").summernote({
      height: 200,
      tabsize: 2,
      toolbar: [["style", ["bold", "italic", "underline"]], ["para", ["ul", "ol"]]],
      tooltip: false,
      callbacks: {
        onChange: function (contents) {
          formik.setFieldValue("declaration", contents);
        },
      },
    });

    return () => {
      $(".declaration_summernote").summernote("destroy");
    };
  }, []);

  useEffect(() => {
    const fetchImages = () => {
      const imageList = [
        { label: "Room Advance Print (A4 & A5)", image: "RoomAdvancePrint.png" },
        { label: "ShiftRoom Advance Print (A4 & A5)", image: "ShiftRoomAdvancePrint.png" },
        { label: "Advance Print (3Inch)", image: "AdvancePrintThreeInch.png" },
      ];

      const formattedImages = imageList.map(({ label, image }) => ({
        label: label,
        value: require(`assets/images/printformats/${image}`),
      }));

      setImages(formattedImages);
    };

    fetchImages();
  }, []);

  useEffect(() => {
    if (!printConfig.billFormat) {
      const defaultImage = images.find((img) => img.label === "Room Advance Print (A4 & A5)");
      if (!printConfig.billFormat && defaultImage) {
        setSelectedImage(defaultImage.value);
        formik.setFieldValue("billFormat", defaultImage.value);
      }
    } else {
      const selectedImage = images.find((img) => img.value.includes(printConfig.billFormat));
      if (selectedImage) {
        setSelectedImage(selectedImage.value);
        formik.setFieldValue("billFormat", selectedImage.billFormat);
      }
    }
  }, [printConfig, images]);

  const formik = useFormik({
    initialValues: {
      note: printConfig?.note || "",
      description: printConfig?.description || "",
      footerSlogan: printConfig?.footerSlogan || "",
      font_size: printConfig?.font_size || "",
      font_family: printConfig?.font_family || "",
      declaration: printConfig?.declaration || "",
      billFormat: printConfig?.billFormat || ""
    },
    enableReinitialize: true,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      const formData = new FormData();
    
      if (sign) {
        formData.append("sign_upload", sign);
      }
       else if (printConfig.sign_upload) {
        formData.append("sign_upload", printConfig.sign_upload);
      }
       else {
        formData.append("sign_upload", "");
      }
      formData.append("note", values.note);
      formData.append("description", values.description);
      formData.append("footerSlogan", values.footerSlogan);
      formData.append("font_size", values.font_size);
      formData.append("font_family", values.font_family);
      formData.append("declaration", values.declaration);
      formData.append("billFormat", values.billFormat || printConfig.billFormat);
      formData.append("hotelId", hid);

      addPrintConfig(formData, dbName)
        .then((res) => {
          setResponse(messages.success.updatedDetails(masterName));
          setAlertModal(true);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            setResponse(messages.failed.alreadyInUse(masterName));
          } else {
            setResponse(messages.failed.updateFailed(masterName));
          }
          setErrorAlertModal(true);
        });
    },
  });

  return (
    <React.Fragment>
      <AlertModal
        show={alertModal}
        onOkClick={() => setAlertModal(false)}
        onCloseClick={() => setAlertModal(false)}
        res={response}
      />
      <ErrorAlertModal
        show={errorAlertModal}
        onOkClick={() => setErrorAlertModal(false)}
        onCloseClick={() => setErrorAlertModal(false)}
        res={response}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Property" breadcrumbItem="Print Configurations" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Note</Label>
                          <Input
                            name="note"
                            placeholder="Enter Your Note"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.note}
                          />
                        </FormGroup>
                      </Col>
                    {/* </Row> */}

                    {/* <Row> */}
                      <Col lg="6">
                        <FormGroup>
                          <Label>Hotel Description</Label>
                          <Input
                            name="description"
                            placeholder="Enter Hotel Description"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.description}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Footer Slogan</Label>
                          <Input
                            name="footerSlogan"
                            placeholder="Enter Footer Slogan"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.footerSlogan}
                          />
                        </FormGroup>
                      </Col>
                    {/* </Row>

                    <Row> */}
                      <Col lg="6">
                        <FormGroup>
                          <Label>Font Size</Label>
                          <Input
                            type="select"
                            name="font_size"
                            onChange={formik.handleChange}
                            value={formik.values.font_size}
                            disabled
                          >
                            <option value="">Select Font Size</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Font Family</Label>
                          <Input
                            type="select"
                            name="font_family"
                            onChange={formik.handleChange}
                            value={formik.values.font_family}
                            disabled
                          >
                            <option value="">Select Font Family</option>
                            <option value="Times New Roman">Times New Roman</option>
                            <option value="Arial">Arial</option>
                            <option value="Courier">Courier</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    {/* </Row>

                    <Row> */}
                     
                      <Col lg="6">
                   <FormGroup>
                        <Label>Sign</Label>
                        {/* File input to upload new signature */}
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={(e) => handleFileChange(e.target.files[0])}
                        />
                        {/* Display image preview for the newly uploaded file */}
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt="Preview"
                            style={{ width: "100px", height: "auto", marginTop: "10px" }}
                          />
                        )}
                        {/* Display existing uploaded signature from the printConfig response */}
                        {printConfig.sign_upload && !imagePreview && (
                          <div className="mt-2">
                            <img
                              src={printConfig.sign_upload}
                              alt="Uploaded Signature"
                              style={{ width: "100px", height: "auto", marginTop: "10px" }}
                            />
                          </div>
                        )}
                      </FormGroup>
                    </Col>

                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Declaration</Label>
                          <div>
                            <div
                              className="declaration_summernote"
                              onChange={(e) => formik.setFieldValue("declaration", e.target.value)}
                            ></div>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Bill Format</Label>
                          <Select
                            options={images}
                            value={images.find((img) => img.value === selectedImage)}
                            onChange={(option) => {
                              formik.setFieldValue("billFormat", option.value);
                              setSelectedImage(option.value);
                            }}
                          />
                          {selectedImage && (
                            <div className="mt-2">
                              <img
                                src={selectedImage}
                                alt="Bill Format Preview"
                                style={{ maxWidth: "100%", height: "auto" }}
                              />
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="justify-content-end">
                        <Button type="submit" color="primary">
                          Submit
                        </Button>
                        <Button
                          type="button"
                          color="secondary"
                          className="ms-2"
                          onClick={() => navigate("/dashboard")}
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PrintConfig);
