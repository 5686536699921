import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody

} from "reactstrap";

// Pages Components
import GuestComponent from "./guestComponent";
import DayWiseChart from "./barChart";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getRoomstatusCount, getCheckinOutCount, getReservedRoomCount,getHotelById,getCashInHandAmount,getReceivingAndRefundAmount } from "../../middleware/backent_helper";
import { PieChart, Pie, Cell, Tooltip} from "recharts";
//i18n
import { withTranslation } from "react-i18next";

const Dashboard = props => {
  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  );
  const [roomStatusCount, setRoomStatus] = useState([]);
  const [CheckInOutCount, setCheckinOutCount] = useState([]);
  const [ReservedRoomCount, setReservedRoomCount] = useState([]);
  const [PaymentDetails, setCashInHandAmount] = useState([]);
  const [ReceivingRefundAmount, setReceivingRefundAmount] = useState([]);
  const [hoteldetails, setHotel] = useState([]);
  const reports = [
    { title: "Vacant", iconClass: "bx bx-hotel", Count: roomStatusCount[0]?.RoomCount || 0, color: "#28a745" },
    { title: "Occupied", iconClass: "bx bx-lock", Count: roomStatusCount[1]?.RoomCount || 0, color: "#dc3545" },
    { title: "House Keeping", iconClass: "bx bx-spray-can", Count: roomStatusCount[2]?.RoomCount || 0, color: "orange" },
    { title: "Maintanance", iconClass: "bx bx-wrench", Count: roomStatusCount[3]?.RoomCount || 0, color: "#343a40" },
    { title: "Total Check-In", iconClass: "bx bx-user-check", Count: CheckInOutCount[0]?.CheckedInCount || 0, color: "#17a2b8" },
    { title: "Total Check-Out", iconClass: "bx bx-exit", Count: CheckInOutCount[0]?.CheckedOutCount || 0, color: "#6c757d" },
    { title: "Blocked Rooms", iconClass: "bx bx-block", Count: roomStatusCount[3]?.RoomCount || 0, color: "#343a40" },
    {
      title: "Reserved Rooms", iconClass: "bx bx-phone-call bx-check", Count: ReservedRoomCount[0]?.ReservationCount
        || 0, color: "#8585F3"
    },
  ];

  const Payments = [
    { title: "Cash In Hand", iconClass: "bx bx-money", Amount: PaymentDetails.CashInHand, color: "#28a745" },
    { title: "Credit Net Payment", iconClass: "bx bx-credit-card", Amount: PaymentDetails.CreditNetAmount, color: "orange" },
    { title: "Total Receiving", iconClass: "bx bx-money", Amount: ReceivingRefundAmount.TotalReceivedAmount, color: "#28a745" },

    {
      title: "Total Posible Refund", iconClass: "bx bx-money", Amount: ReceivingRefundAmount.TotalPosibleRefundAmount, color: "#28a745"
    },
  ];
  //Charts
  const data = [
    { name: "Vacant", value: roomStatusCount[0]?.RoomCount || 0 },
    { name: "Occupied", value: roomStatusCount[1]?.RoomCount || 0 },
    { name: "HouseKeeping", value: roomStatusCount[2]?.RoomCount || 0 },
    { name: "Maintanance", value: roomStatusCount[3]?.RoomCount || 0 },
  ];

  const COLORS = [roomStatusCount[0]?.StatusColor, roomStatusCount[1]?.StatusColor, roomStatusCount[2]?.StatusColor, roomStatusCount[3]?.StatusColor];

  //meta title
  document.title = "StayPro | Dashboard";


  useEffect(() => {

    getRoomstatusCount(dbName)
      .then(response => {
        setRoomStatus(response.data.data);

      })
     
    getCheckinOutCount(dbName)
      .then(response => {
        setCheckinOutCount(response.data.data);

      })
     

    getReservedRoomCount(dbName)
      .then(response => {
        setReservedRoomCount(response.data.data);

      })
    
      getCashInHandAmount(dbName)
      .then(response => {
        setCashInHandAmount(response.data.data);

      })

      getReceivingAndRefundAmount(dbName)
      .then(response => {
        setReceivingRefundAmount(response.data.data);

      })

    getHotelById(hid, dbName)
      .then((res) => {
          setHotel(res.data.data);
      }); 

  }, []);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col xl="3">
              <GuestComponent />
            </Col>
            <Col xl="9">
              <Row>
                {/* Reports */}
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <p className="text-muted fw-medium">
                              {report.title}
                            </p>
                            <h4 className="mb-0">{report.Count}</h4>
                          </div>
                          <div className="avatar-sm rounded-circle  align-self-center">
                            <span className="avatar-title rounded-circle bg-light">
                              <i
                                className={
                                  "bx " + report.iconClass + " font-size-24"

                                }
                                style={{ color: report.color }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

            </Col>
          </Row>

          <Row>
            {/* Payment */}
            {Payments.map((report, key) => (
              <Col md="3" key={"_col_" + key}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <p className="text-muted fw-medium">
                        
                          {report.title}
                        </p>
                        <h4 className="mb-0">{hoteldetails?.currency?.match(/\(([^)]+)\)/)?.[1]} {report.Amount}</h4>
                      </div>
                      <div className="avatar-sm rounded-circle align-self-center">
                        <span className="avatar-title rounded-circle bg-light">
                          <i
                            className={
                              "bx " + report.iconClass + " font-size-24"
                            }
                            style={{ color: report.color }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>


          <Row>
            <Col lg="6" >
              <div className="bg-white p-3 rounded shadow-sm w-100">
                <h4 className="mb-4">Rooms Analytics</h4>
                <div className="pie-chart-container ">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={data}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      innerRadius={90}
                      fill="#8884d8"
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>

                  <div className="status-details mt-4">
                    {data.map((entry, index) => (
                      <div key={index} className="status-item d-flex align-items-center mb-2">
                        <span className="statuschart"
                          style={{
                            
                            backgroundColor: COLORS[index % COLORS.length],
                            
                          }}
                        ></span>
                        <span>{entry.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
            <Col lg="6">
            < DayWiseChart/>
          </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};



export default withTranslation()(Dashboard);
