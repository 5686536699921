import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  Col,
  Container,
  Row,
} from "reactstrap";
import TableContainer from "../../../components/Common/TableContainer";
import withRouter from "../../../components/Common/withRouter";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { getHotelById, getAllPettyCash, categoryList } from "../../../middleware/backent_helper";
import { Particulars, Type, Total, } from "./indexCol";

const PettyCashOverall = props => {
  //meta title
  document.title = "Petty Cash OverAll Report | Income & Expense ";

  const [CategoryList, setCategoryList] = useState([]);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [pettyCashTotal, SetpettyCashTotal] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0)
  const [totalExpense, setTotalExpense] = useState(0)

  const [hid, setHid] = useState(() => {
    const storedHotelId = localStorage.getItem('hotelId')
    return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
  });
  const [hotelUser, setHotel] = useState([]);
  const [dbName, setDbName] = useState(
    localStorage.getItem('dbName')
  )
  const datePickerRef = useRef(null);
  const today = new Date();
  const [selectedDates, setSelectedDates] = useState([
    {
      startDate: today,
      endDate: today,
      key: "selection",
    },
  ]);
  const formattedDateRange = `${format(selectedDates[0].startDate, "dd-MM-yyyy")} / ${format(selectedDates[0].endDate, "dd-MM-yyyy")}`;

  useEffect(() => {
    getHotelById(hid)
      .then(response => {
        const hotelData = response.data.data;
        setHotel(hotelData)
      })
  }, []);

  const calculateTotals = (data) => {
    const totals = {};

    data.forEach(item => {
      const { categoryName, categoryType, cash, debit } = item;
      if (!totals[categoryName]) {
        totals[categoryName] = {};
      }

      if (!totals[categoryName][categoryType]) {
        totals[categoryName][categoryType] = 0;
      }
      // Convert cash to number if it's a string
      const cashValue = typeof cash === 'string' ? parseFloat(cash) : cash;
      totals[categoryName][categoryType] += cashValue || 0;
      const debitValue = typeof cash === 'string' ? parseFloat(debit) : debit;
      totals[categoryName][categoryType] += debitValue || 0;
    });
    SetpettyCashTotal(totals)
    return totals;
  };

  // Calculate aggregated totals
  const totals = useMemo(() => calculateTotals(CategoryList), [CategoryList]);
  
  // Function to filter out duplicates based on Particulars for display
  const filterUniqueData = (data) => {
    const seen = new Set();
    return data.filter(item => {
      const isDuplicate = seen.has(item.categoryName);
      seen.add(item.categoryName);
      return !isDuplicate;
    });
  }

  const uniqueData = useMemo(() => filterUniqueData(CategoryList), [CategoryList]);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        Cell: ({ row, rows }) => {
          return (
            <div className="table-body">
              {rows.length - row.index}
            </div>
          );
        },
      },

      {
        Header: "Particulars",
        accessor: "categoryName",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-body">
              <Particulars {...cellProps} />
            </div>
          );
        },
      },

      {
        Header: "Type",
        accessor: "categoryType",
        id: "type",
        filterable: true,
        Cell: cellProps => {
          return (
            <div className="table-body">
              <Type {...cellProps} />
            </div>
          );
        },
      },

      {
        Header: "Total",
        accessor: "categoryName",
        id: "total",
        filterable: true,
        Cell: cellProps => {
          const { value } = cellProps.cell;
          const categoryType = cellProps.row.original.categoryType;
          return (
            <div className="table-cell text-center">
              <Total {...cellProps} categoryName={value} categoryType={categoryType} totals={totals} />
            </div>
          );
        },
      },

    ],
    [totals]
  );

  useEffect(() => {
    getAllPettyCash(hid, dbName).then(response => {

      const hotelData = response.data.data;


      let income = 0;
      let expense = 0;
      hotelData.forEach(record => {
        if (record.categoryType === "Income") {
          income += parseFloat(record.cash);
        } else if (record.categoryType === "Expense") {
          expense += parseFloat(record.debit);
        }
      });
      setTotalIncome(income);
      setTotalExpense(expense);
      setCategoryList(hotelData);
    })
  }, [])


  const handleDateRangeChange = (ranges) => {
    setSelectedDates([ranges.selection]);
    setDatePickerOpen(false);
  };

  const handleInputClick = () => {
    setDatePickerOpen(true);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleSearch = (startDate, endDate) => {
    if (!startDate || !endDate) return;

    const formattedFromDate = formatDate(startDate);
    const formattedToDate = formatDate(endDate);

    //  fetch Petty Cash data
    getAllPettyCash(dbName, formattedFromDate, formattedToDate)
      .then(response => {
        const hotelData = response.data.data;

        let income = 0;
        let expense = 0;

        //fetched data and calculate income/expense
        hotelData.forEach(record => {
          if (record.categoryType === "Income") {
            income += parseFloat(record.cash);
          } else if (record.categoryType === "Expense") {
            expense += parseFloat(record.debit);
          }
        });

        // Set the state with the calculated data
        setTotalIncome(income);
        setTotalExpense(expense);
        setCategoryList(hotelData);
      })
      .catch((err) => {
        console.error("Error fetching Petty Cash data:", err);
      });
  };

  useEffect(() => {

    const defaultStartDate = new Date();
    const defaultEndDate = new Date();
    handleSearch(defaultStartDate, defaultEndDate);
  }, [hid, dbName]);
  
  const handleClickOutside = (event) => {
    if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
      setDatePickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Income & Expenses" breadcrumbItem="Month Wise Petty Cash OverAll Report" />
          <div className="mb-3">
            <Row>
              <Col lg="6" xs="12" md="6">
                <div className="input-group">
                  <div className="input-group-text">
                    <span className="input-group-prepand">From - To</span>
                  </div>
                  <input
                    type="text"
                    value={formattedDateRange}
                    readOnly
                    onClick={handleInputClick}
                    placeholder="Select Date Range"
                    className="form-control"
                  />
                  <label className="input-group-text" htmlFor="inputGroupFile01">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>

              </Col>
              <Col lg="6" xs="6" md="4" className="mt-3 mt-md-0  text-md-left">
                <button
                  className="btn btn-success"
                  onClick={() => handleSearch(selectedDates[0].startDate, selectedDates[0].endDate)}
                >
                  <span className="fas fa-search" /> Search
                </button>
              </Col>
            </Row>
            {datePickerOpen && (
              <div ref={datePickerRef} className="date-range-picker-dropdown mt-1">
                <DateRangePicker
                  onChange={handleDateRangeChange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={selectedDates}
                  direction="horizontal"
                  className="date-range-picker-dropdown"

                />
              </div>
            )}
          </div>
          <Row>
            <Col lg="12">
              <TableContainer
                columns={columns}
                pettyCashTotal={pettyCashTotal}
                data={uniqueData}
                hotel={hotelUser}
                overAllPettyCashReport={true}
                isGlobalFilter={true}
                customPageSize={10}
                className="custom-header-css"
                totalIncome={totalIncome}
                totalExpense={totalExpense}

              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}
export default withRouter(PettyCashOverall);