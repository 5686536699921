const Description = (cell) => {
    return cell.value ? cell.value : '';
};

const InDate = (cell) => {
    return cell.value ? cell.value : '';
};

const CreditCard = (cell) => {
    return cell.value ? cell.value : '';
};

const Online = (cell) => {
    return cell.value ? cell.value : '';
};

const Cash = (cell) => {
    return cell.value ? cell.value : '';
};

const Expenses = (cell) => {
    return cell.value ? cell.value : '';
};

const Remarks = (cell) => {
    return cell.value ? cell.value : '';
};

export {
    Description,
    InDate,
    CreditCard,
    Online,
    Cash,
    Expenses,
    Remarks
};