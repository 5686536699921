import React, { useEffect, useState, useRef, useMemo } from "react";
import {

    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    Table

} from "reactstrap";

import TableContainer from "../../../components/Common/TableContainer";

import { Room, Guest, Pax, CheckIn, CityState, Company, Tariff, Advance, Received, Refund } from "./indexCol";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import { getHotelById, getGuestAndInventoryDetails, getInventory } from "../../../middleware/backent_helper";

const guestandInventory = props => {
    //meta title
    document.title = "Guest and Inventory | Guest and Inventory ";


    const [GuestAndInventoryDetails, setGuestAndInventoryDetails] = useState([]);
    const [Totaldetails, setTotals] = useState([]);
    const [InventoryList, setInventoryList] = useState([]);
    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    );


    const columns = useMemo(() => [
        {
            Header: "S.No",
            Cell: ({ row, rows }) => {
                return (
                    <div className="table-body">
                        {rows.length - row.index}
                    </div>
                );
            },
        },
        {
            Header: "Room",
            accessor: "roomNo",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Room {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Guest",
            accessor: "guestName",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Guest {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Pax",
            accessor: "totalGuest",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Pax {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "CheckIn",
            accessor: "checkInDate",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <CheckIn {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "City / State",
            accessor: "city",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <CityState {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Company",
            accessor: "companyName",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Company {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Tariff(Inc. GST)",
            accessor: "tariff",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Tariff {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Advance",
            accessor: "advanceAmount",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Advance {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Receiving",
            accessor: "ReceivingAmount",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Received {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Refund",
            accessor: "RefundAmount",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Refund {...cellProps} />
                    </div>
                );
            },
        },
    ]);


    useEffect(() => {
        getGuestAndInventoryDetails(dbName)
            .then((res) => {
                setGuestAndInventoryDetails(res.data.data);
                setTotals(res.data.totals);
            });

    }, []);

    useEffect(() => {
        getInventory(hid, dbName)
            .then((res) => {
                setInventoryList(res.data.data);
            });
    }, [])

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
    });

    const formattedTime = currentDate.toLocaleTimeString('en-GB', {
        hour: '2-digit',
        minute: '2-digit'

    });


    return (
        <React.Fragment>

            <div className="">
                <Container fluid>
                    <h5>Guest In The House and Stock Inventory {formattedDate} {formattedTime}</h5>

                    <Row>
                        <Col lg="12">
                            <TableContainer
                                columns={columns}
                                data={GuestAndInventoryDetails}
                                isGlobalFilter={true}
                                GuestIntheHouseTotalDetails={true}
                                totalAmounts={Totaldetails}
                                customPageSize={10}
                                className="custom-header-css"
                            />

                        </Col>
                        <Col lg={12}>
                            <Card>
                                <CardHeader><h5>Inventory Stock List</h5></CardHeader>
                                <CardBody>
                                    <Table bordered responsive>
                                        <thead>
                                            <tr>
                                                <th className="text-center">S.No.</th>
                                                <th className="text-center">Items</th>
                                                <th className="text-center">Available</th>
                                                <th className="text-center">Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {InventoryList.map((item, index) => (
                                                <tr key={index}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">{item.name}</td>
                                                    <td className="text-center">{parseInt(item.availableQuantity, 10)}</td>
                                                    <td className="text-center">{item.cost}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>


                                </CardBody>
                            </Card>
                        </Col>


                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default guestandInventory;
