const Room = (cell) => {
    return cell.value ? cell.value : '';
};
const Guest = (cell) => {
    return cell.value ? cell.value : '';
};

const Pax = (cell) => {
    return cell.value ? cell.value : '';
};

const CheckIn = (cell) => {
    return cell.value ? cell.value : '';
};

const CityState = (cell) => {
    return cell.value ? cell.value : '';
};

const Company = (cell) => {
    return cell.value ? cell.value : '';
};

const Tariff = (cell) => {
    return cell.value ? cell.value : '';
};
const Advance = (cell) => {
    return cell.value ? cell.value : '0.00';
};
const Received = (cell) => {
    return cell.value ? cell.value : '0.00';
};
const Refund = (cell) => {
    return cell.value ? cell.value : '0.00';
};

export {
    Room,
    Guest,
    Pax,
    CheckIn,
    CityState,
    Company,
    Tariff,
    Advance,
    Received,
    Refund
};