import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table";
import { Table, Row, Col, Button, Input, Card, CardBody } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import JobListGlobalFilter from "./GlobalSearchFilter";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const EmptyBoxIcon = () => (
  <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
    <title>Simple Empty</title>
    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
      <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
      <g fillRule="nonzero" stroke="#d9d9d9">
        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path>
      </g>
    </g>
  </svg>
);

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  isJobListGlobalFilter
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <Col sm={4}>
        <div className="search-box d-inline-block">
          <div className="position-relative">
            <label htmlFor="search-bar-0" className="search-label">
              <span id="search-bar-0-label" className="sr-only">
                Search this table
              </span>
              <input
                onChange={e => {
                  setValue(e.target.value);
                  onChange(e.target.value);
                }}
                id="search-bar-0"
                type="text"
                className="form-control"
                placeholder={`Enter min. ${count} char.`}
                value={value || ""}
              />
            </label>
            <i className="bx bx-search search-icon"></i>

          </div>
        </div>

      </Col>
      {isJobListGlobalFilter && (
        <JobListGlobalFilter />
      )}

    </React.Fragment>
  );
}

const TableContainer = ({
  columns,
  data,
  hotel, // Income & expenses
  pettyCashTotal, //OverAll PettyCash Report
  finalBalanceAmountReport,
  BalanceTotalIncomeandExpense,
  totalCardAmount,
  totalOnlineAmount,
  totalCashAmount,
  totalDebitAmount,
  totalIncome,
  totalExpense,
  isAddHotelList,
  isAddPaymentTypeList,
  isAddAssetsList,
  isAddEbReadingList,
  isAddPlanList,
  isAddCustomer,
  isAddRoomMapList,
  isAddTaxMasterList,
  isAddIdentityList,
  isAddElectricityReadingList,
  isAddPortalTypeList,
  isAddGensetDetailsList,
  isGlobalFilter,
  handlePaymentTypeClick,
  handleAssetsClick,
  handleHotelClicks,
  handlePlanClicks,
  handleNewCustomerClick,
  handleRoomMappingClicks,
  handleTaxMasterClicks,
  handleIdentityClicks,
  handleElectricityAccountClicks,
  handlePortalTypeClicks,
  handleGensetDetailsClicks,
  isJobListGlobalFilter,
  isAddOptions,
  isAddUserList,
  isAddRoomServiceList,
  handleOrderClicks,
  handleUserClick,
  isAddCategoryList,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  className,
  isAddPurchaseList,
  isAddMenusList,
  handleMenuClick,
  isAddRoomsList,
  isAddInventoryStockList,
  handleRoomsClick,
  isAddModuleList,
  handleModuleClick,
  isAddRoomTypeList,
  handleRoomTypeClick,
  handleRoomServiceClick,
  handleCategoryClick,
  handleInventoryStockClick,
  handlePurchaseClick,
  // INCOME AND EXPENSES
  isAddAmountToOwnerClick,
  handleAmountToOwnerClick,
  isAddpettyCashList,
  handlePettyCashClick,
  isAddIncomeAndExpenses,
  isAddIncomeAndExpensesReport,
  isAddBalanceReport,
  handleIncomeAndExpneseClick,
  overAllPettyCashReport,
  //GuestIn the House
  GuestIntheHouseTotalDetails,
  totalAmounts,
  // Accounts
  isAddInventorySales,
  handleInventorySaleClick,
  // RESERVATION
  handleViewReservation,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },

  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters, 
    useSortBy,
    useExpanded,
    usePagination
  );

 

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const currency = hotel?.currency || '0.00';
  const currencySymbol = currency.match(/\((.*?)\)/)?.[1] || '0.00';

  const dateColumnIndex = 2;
  const cardColumnIndex = 3;
  const onlineColumnIndex = 4;
  const cashColumnIndex = 5;
  const expenseColumnIndex = 6;
  const remarksColumnIndex = 7;

  

  return (
    <Fragment>
      <Row className="xs-2">
        <Col sm={2}>
          <div className="d-flex">
            <select
              className="form-select form-select-md"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isJobListGlobalFilter={isJobListGlobalFilter}
          />
        )}
        {isAddOptions && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handleOrderClicks}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Order
              </button>
            </div>
          </Col>
        )}
        {isAddPurchaseList && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handlePurchaseClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Item
              </button>
            </div>
          </Col>
        )}

        {isAddRoomTypeList && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handleRoomTypeClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Room Type
              </button>
            </div>
          </Col>
        )}

        {isAddMenusList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleMenuClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Menu
              </button>
            </div>
          </Col>
        )}
        {isAddCategoryList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleCategoryClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Category
              </button>
            </div>
          </Col>
        )}
        {isAddInventoryStockList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleInventoryStockClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Inventory Stock
              </button>
            </div>
          </Col>
        )}
        {isAddRoomServiceList && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handleRoomServiceClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Room service
              </button>
            </div>
          </Col>
        )}
        {isAddModuleList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleModuleClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Module
              </button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handleUserClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New User
              </button>
            </div>
          </Col>
        )}

        {isAddHotelList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleHotelClicks}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Hotel
              </button>
            </div>
          </Col>
        )}

        {isAddAssetsList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleAssetsClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Asset
              </button>
            </div>
          </Col>
        )}


        {isAddPaymentTypeList && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handlePaymentTypeClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Payment Type
              </button>
            </div>
          </Col>
        )}

        {isAddCustomer && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handleNewCustomerClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Customer
              </button>
            </div>
          </Col>
        )}

        {isAddPlanList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handlePlanClicks}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Plan
              </button>
            </div>
          </Col>
        )}

        {isAddRoomMapList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleRoomMappingClicks}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Map Room
              </button>
            </div>
          </Col>
        )}

        {isAddTaxMasterList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleTaxMasterClicks}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Tax
              </button>
            </div>
          </Col>
        )}

        {isAddIdentityList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleIdentityClicks}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add  Identity Details
              </button>
            </div>
          </Col>
        )}

        {isAddElectricityReadingList && (
          <Col sm="6">
            <div className="text-sm-end">


              <button className="btn-label btn btn-danger" onClick={handleElectricityAccountClicks}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add Electricity Account
              </button>
            </div>
          </Col>
        )}

        {isAddPortalTypeList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handlePortalTypeClicks}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add Portal Type
              </button>
            </div>
          </Col>
        )}

        {isAddGensetDetailsList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleGensetDetailsClicks}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add Genset Details
              </button>
            </div>
          </Col>
        )}

        {isAddEbReadingList && (
          <Col sm="6">
            <div className="text-sm-end">
              <Button
                type="button"
                color="danger"
                className="btn mb-2 me-2"
                onClick={handleEbAccountClicks}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                  Create EB Account
              </Button>
            </div>
          </Col>
        )}

        {isAddRoomsList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleRoomsClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add New Room
              </button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="6">
            <div className="text-sm-end">

              <button className="btn-label btn btn-danger" onClick={handleCustomerClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add Customers
              </button>
            </div>
          </Col>
        )}

        {/* INCOME & EXPENSES */}
        {isAddAmountToOwnerClick && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handleAmountToOwnerClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add Expense Detail
              </button>
            </div>
          </Col>
        )}
        {isAddpettyCashList && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handlePettyCashClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add Income And Expenses
                </button>
            </div>
          </Col>
        )}
        {/* {isAddIncomeAndExpenses && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handleIncomeAndExpneseClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add Income and Expenses
              </button>
            </div>
          </Col>
        )} */}
        {/* Accounts */}
        {isAddInventorySales && (
          <Col sm="6">
            <div className="text-sm-end">
              <button className="btn-label btn btn-danger" onClick={handleInventorySaleClick}>
                <i className="bx bx-plus-circle label-icon"></i>
                Add Inventory Sale
              </button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={`custom-table ${className}`}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th key={column.id}>
                    <div  {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.length === 0 ? (
              <tr>
                <td colSpan={columns.length} className="no-data">
                  <div className="empty-box">
                    <div className="no-data-content">
                      <EmptyBoxIcon className="no-data-icon" />
                      <span className="no-data-text">No data available</span>
                    </div>
                  </div>
                </td>
              </tr>
            ) : (

              page.map(row => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr>
                      {row.cells.map(cell => (
                        <td key={cell.id} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  </Fragment>
                );
              })
            )}
          </tbody>
          {(isAddIncomeAndExpenses && page.length != 0 ) && (
            <tfoot className="table-footer">
              <tr>
                {Array.from({ length: dateColumnIndex }, (_, i) => (
                  <td key={`empty-${i}`} />
                ))}
                <td><b>Total :{currencySymbol}</b></td>
                {/* Card, Online, Cash, Expense, and Remarks totals */}
                <td><b> {totalCardAmount.toFixed(2)}</b></td>
                <td><b>{totalOnlineAmount.toFixed(2)}</b></td>
                <td><b>{totalCashAmount.toFixed(2)}</b></td>
                <td><b>{totalDebitAmount.toFixed(2)}</b></td>
                <td colSpan={headerGroups[0].headers.length - remarksColumnIndex - 1}><b>
                  Cash Balance: {(totalCashAmount - totalDebitAmount).toFixed(2)}</b>
                </td>
              </tr>
              <tr className="table-footer">
                {Array.from({ length: dateColumnIndex }, (_, i) => (
                  <td key={`empty-${i}`} />
                ))}
                <td><b>Income :{currencySymbol}</b></td>
                <td><b>{(totalCardAmount + totalOnlineAmount + totalCashAmount).toFixed(2)}</b></td>
                <td><b>Expenses :{currencySymbol}</b></td>
                <td><b>{totalDebitAmount.toFixed(2)}</b></td>
                <td><b>Total Balance :{currencySymbol}</b></td>
                <td colSpan={headerGroups[0].headers.length - remarksColumnIndex - 1}><b>
                {(totalCardAmount + totalOnlineAmount + totalCashAmount - totalDebitAmount).toFixed(2)}</b>
                </td>
              </tr>
            </tfoot>
          )}
           {(isAddIncomeAndExpensesReport && page.length != 0 ) && (
            <tfoot className="table-footer">
              <tr>
                {Array.from({ length: dateColumnIndex }, (_, i) => (
                  <td key={`empty-${i}`} />
                ))}
                <td><b>Total :</b></td>
                {/* Card, Online, Cash, Expense, and Remarks totals */}
                <td><b> {currencySymbol} {totalCardAmount.toFixed(2)}</b></td>
                <td><b>{currencySymbol} {totalOnlineAmount.toFixed(2)}</b></td>
                <td><b>{currencySymbol} {totalCashAmount.toFixed(2)}</b></td>
                <td><b>{currencySymbol} {totalDebitAmount.toFixed(2)}</b></td>
                <td colSpan={headerGroups[0].headers.length - remarksColumnIndex - 1}><b>
                  Cash Balance: {currencySymbol} {(totalCashAmount - totalDebitAmount).toFixed(2)}</b>
                </td>
              </tr>
             
            </tfoot>
          )}
          {(isAddBalanceReport && page.length != 0 ) && (
            <tfoot className="table-footer">
              <tr>
                {Array.from({ length: dateColumnIndex }, (_, i) => (
                  <td key={`empty-${i}`} />
                ))}
                <td><b>Total :{currencySymbol}</b></td>
                <td><b>  {Number(BalanceTotalIncomeandExpense.TotalIncome || 0).toFixed(2)}</b></td>
                <td><b>{Number(BalanceTotalIncomeandExpense.TotalExpense || 0).toFixed(2)}</b></td>
                <td><b> {Number(BalanceTotalIncomeandExpense.TotalIncome - BalanceTotalIncomeandExpense.TotalExpense || 0).toFixed(2)}</b></td>
               
              </tr>
              <tr className="table-footer">
                {Array.from({ length: dateColumnIndex }, (_, i) => (
                  <td key={`empty-${i}`} />
                ))}
                
                <td></td><td></td><td>Total Balance:{currencySymbol}</td>
                <td><b>{Number(finalBalanceAmountReport || 0).toFixed(2)}</b>
                </td>
              </tr>
             
            </tfoot>
          )}
          {(overAllPettyCashReport && page.length != 0 ) && (
            <tfoot className="table-footer">
              <tr>
                {Array.from({ length: dateColumnIndex }, (_, i) => (
                  <td key={`empty-${i}`} />
                ))}
                <td>Income Total: {currencySymbol} {totalIncome.toFixed(2)} </td>
                <td>Expenses Total: {currencySymbol} {totalExpense.toFixed(2)}</td>
              </tr>
            </tfoot>
          )}
           {(GuestIntheHouseTotalDetails && page.length != 0 ) && (
            <tfoot className="table-footer">
              <tr>
                {Array.from({ length: dateColumnIndex }, (_, i) => (
                  <td key={`empty-${i}`} />
                ))}
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td><b>Total :</b></td>
                <td><b>{Number(totalAmounts.TotalReceivingAmount || 0).toFixed(2)}</b></td>
                <td><b>  {Number(totalAmounts.TotalRefundAmount || 0).toFixed(2)}</b></td>
                
              </tr>
             
            </tfoot>
          )}
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              className="paging-btn"
              color="paging"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              className="paging-btn"
              color="paging"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              className="paging-btn"
              color="paging"
              onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              className="paging-btn"
              color="paging"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;