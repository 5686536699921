import { del, get, post, put, post2 } from "./helpers/api_helper"
import * as url from "./helpers/url_helper"
export const Api_url = "/api"

// Helper functions
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  return user ? JSON.parse(user) : null
}

const isUserAuthenticated = () => getLoggedInUser() !== null
// Exported API calls
export const userLogin = data => post(url.POST_LOGIN, data)
export const userRegister = data => post(url.POST_REGISTER, data)
export const verifyToken = data => post(url.VERIFY_TOKEN, data)
export const clearToken = () => post(url.CLEAR_TOKEN)
export const forgotPassword = data => post(url.FORGOT_PASSWORD, data)
export const forgotVerifyOTP = data => post(url.FORGOTVERIFY, data)
export const verifyOTP = data => post(url.VERIFY, data)
export const resetPassword = data => post(url.RESET_PASSWORD, data)
// Users
export const getAllUsers = () => get(url.GET_USER)
export const usersRead = companyId => get(`${url.GET_USERS}/${companyId}`)
export const deleteUser = data => post(url.DELETE_MENU, data)
// menu master
export const getAllMenus = () => get(url.GET_Menus)
export const postMenu = data => post(url.ADD_NEW_Menus, data)
export const putMenu = data => put(url.PUT_Menu, data)
// modules master
export const menuRead = () => get(url.GET_MENU)
export const addModules = data => post(url.ADD_MODULES, data)
export const getModules = () => get(url.GET_MODULES)
export const updateModules = data => post(url.UPDATE_MODULES, data)
export const deleteModules = data => del(`${url.DELETE_MODULES}?id=${data.id}`)
// Hotel master
export const deleteHotel = data => del(`${url.DELETE_HOTEL}?id=${data.id}`)
export const hotelRegister = (data, roleId) => post2(`${url.REGISTER_HOTEL}?id=${roleId}`, data)
export const updateHotel = data => post2(url.UPDATE_HOTEL, data)
export const getSelectedPlan = data => post(url.GET_SPECIFIC_PLAN, data)
export const getMenusPlan = () => get(url.GET_MENU_ITEMS)
export const getHotelUser = id => get(`${url.GET_HOTEL_USERDETAIL}?id=${id}`)
export const updatePassword = (hid, dbName, data) => post(`${url.UPDATE_PASSWORD}?db=${dbName}&id=${hid}`, data)
// City, states & country
export const getCountries = () => get(url.GET_COUNTRIES)
export const getStates = country => get(`${url.GET_STATE}?country=${country}`)
export const getCities = state => get(`${url.GET_CITY}?state=${state}`)
export const getAllHotels = () => get(url.GET_ALL_HOTELS)
export const getTimeZones = () => get(url.GET_ALL_TIMEZONE)
// Plan master
export const getPlans = () => get(url.GET_PLANS)
export const planRegister = data => post(url.PLAN_REGISTER, data)
export const getAllPlan = () => get(url.GET_PLAN)
export const updatePlan = data => put(url.UPDATE_PLAN, data)
export const moduleGet = () => get(url.MODULES_GET)
export const deletePlans = data => post(url.DELETE_PLAN, data)
// RoomType
export const postRoomType = data => post(url.ADD_NEW_ROOM_TYPE, data)
export const getAllRoomType = (hid, dbName) => get(`${url.GET_ALLROOM_TYPE}?db=${dbName}&id=${hid}`)
export const putRoomType = data => put(url.UPDATE_ROOM_TYPE, data)
export const deleteRoomType = (id, dbName) => del(`${url.DELETE_ROOM_TYPE}?dbName=${dbName}&id=${id}`)
// Rooms
export const createRoom = data => post(url.CREATE_ROOM, data)
export const updateRoom = data => put(url.UPDATE_ROOM, data)
export const getRooms = (hid, dbName) => get(`${url.GET_ROOMS}?db=${dbName}&id=${hid}`)
export const deleteRooms = (id, dbName) => del(`${url.DELETE_ROOMS}?dbName=${dbName}&id=${id}`)
export const roomTypeRead = (hid, dbName) => get(`${url.GET_ROOM_TYPE}?db=${dbName}&id=${hid}`)
// Room mapping
export const roomTypeIdGet = dbName => get(`${url.ROOM_TYPE_ID_GET}?db=${dbName}`)
export const roomIdGet = dbName => get(`${url.ROOM_ID_GET}?db=${dbName}`)
export const addRoomMapping = data => post(url.ADD_room_mapping, data)
export const getAllRoomMapping = dbName => get(`${url.GET_room_mapping}?db=${dbName}`)
export const editAllRooms = dbName => get(`${url.GET_ROOM_DETAILS}?db=${dbName}`)
export const roomTypeDetailsGet = (roomId, dbName) => get(`${url.GET_ROOM_TYPES_DETAILS}?db=${dbName}&roomId=${roomId}`)
export const updateRoomMaps = data => put(url.UPDATE_ROOM_MAPS, data)
export const deleteRoomMap = (id, dbName) => del(`${url.DELETE_ROOM_MAP}?db=${dbName}&id=${id}`)
// rooms
export const createRoomService = data => post(url.CREATE_ROOM_SERVICE, data)
export const updateRoomService = data => put(url.UPDATE_ROOM_SERVICE, data)
export const getRoomService = (hid, dbName) => get(`${url.GET_ROOM_SERVICE}?db=${dbName}&id=${hid}`)
export const deleteRoomService = (data, dbName) => del(`${url.DELETE_ROOMS_SERVICE}?dbName=${dbName}&id=${data.id}`)
// category master
export const createCategory = data => post(url.CREATE_CATEGORY, data)
export const updateCategory = data => put(url.UPDATE__CATEGORY, data)
export const getCategory = (hid, dbName) => get(`${url.GET_CATEGORY}?db=${dbName}&id=${hid}`)
export const deleteCategory = (data, dbName) => del(`${url.DELETE_CATEGORY}?db=${dbName}&id=${data.id}`)
// inventory master
export const createPurchase = data => post(url.CREATE_PURCHASE, data)
export const updatePurchase = data => post(url.UPDATE_PURCHASE, data)
export const updateExistingPurchase = data => post(url.UPDATE_PURCHASE_EXISTING, data)
export const getPurchase = (hid, dbName) => get(`${url.GET_PURCHASE}?db=${dbName}&id=${hid}`)
export const deletePurchase = (data, dbName) => del(`${url.DELETE_PURCHASE}?db=${dbName}&id=${data.id}`)
// payment type master
export const createPaymentType = data => post(url.CREATE_PAYMENT_TYPE, data)
export const getPaymentTypes = (hid, dbName) => get(`${url.GET_PAYMENT_TYPES}?db=${dbName}&id=${hid}`)
export const deletePaymentType = (data, dbName) => del(`${url.DELETE_PAYMENT_TYPE}?dbName=${dbName}&id=${data.id}`)
// assets master
export const createAssets = data => post(url.CREATE_ASSETS, data)
export const getAssetsList = (hid, dbName) => get(`${url.GET_ASSETS_LIST}?db=${dbName}&id=${hid}`)
export const deleteAssets = (data, dbName) => del(`${url.DELETE_ASSET}?db=${dbName}&id=${data.id}`)
export const editAssets = data => put(url.EDIT_ASSETS, data)
// customer master
export const getAllCustomer = (hid, dbName) => get(`${url.GET_ALL_CUSTOMER}?db=${dbName}&id=${hid}`)
export const addCustomer = (data, hid, dbName) => post2(`${url.ADD_CUSTOMER}?dbName=${dbName}&hotelId=${hid}`, data)
export const deleteCustomer = data => del(url.DELETE_CUSTOMER, data)
export const editCustomer = (data, hid, dbName) => post2(`${url.EDIT_CUSTOMER}?dbName=${dbName}&hotelId=${hid}`, data)
export const deleteCustomerImage = data => del(url.DELETE_CUSTOMER_IMAGE, data)
export const getCustomerDetails = (dbName, Id, HotelId) => get(`${url.GET_CUSTOMER_DETAILS}?dbName=${dbName}&Cust_id=${Id}&hotelId=${HotelId}`)
// Tax master
export const addTax = data => post(url.ADD_NEW_TAX, data)
export const getTax = (hid, dbName) => get(`${url.GET_TAX}?db=${dbName}&id=${hid}`)
export const updateTax = data => put(url.UPDATE_TAX, data)
export const deleteTax = (data, dbName) => del(`${url.DELETE_TAX}?dbName=${dbName}&id=${data.id}`)
// identity master
export const addIdentity = data => post(url.ADD_NEW_IDENTITY, data)
export const getIdentity = (hid, dbName) => get(`${url.GET_IDENTITY}?db=${dbName}&id=${hid}`)
export const updateIdentity = data => put(url.UPDATE_IDENTITY, data)
export const deleteIdentity = (data, dbName) => del(`${url.DELETE_IDENTITY}?db=${dbName}&id=${data.id}`)
// Electricity master
export const addElectricityAccount = data => post(url.ADD_ELECTRICITY_ACCOUNT, data)
export const getElectricityAccount = (hid, dbName) => get(`${url.GET_ELECTRICITY_ACCOUNT}?db=${dbName}&id=${hid}`)
export const updateElectricityAccount = data => put(url.UPDATE_ELECTRICITY_ACCOUNT, data)
export const deleteElectricityAccount = (data, dbName) => del(`${url.DELETE_ELECTRICITY_ACCOUNT}?dbName=${dbName}&id=${data.id}`)
// genset master
export const addGensetDetails = data => post(url.ADD_GENSET_DETAILS, data)
export const getGenset = (hotelId, formattedFromDate, formattedToDate, dbName) => get(`${url.GET_GENSET}?hotelId=${hotelId}&fromDate=${formattedFromDate}&toDate=${formattedToDate}&dbName=${dbName}`)
// Online portal settings master
export const addOnlinePortalType = data => post(url.ADD_ONLINE_PORTAL_TYPE, data)
export const getPortalType = (hid, dbName) => get(`${url.GET_PORTAL_TYPE}?db=${dbName}&id=${hid}`)
export const updatePortalType = data => put(url.UPDATE_PORTAL_TYPE, data)
export const deletePortalType = (data, dbName) => del(`${url.DELETE_PORTAL_TYPE}?db=${dbName}&id=${data.id}`)
// Income and Expenses
// Amount to Owner
export const createAmountToOwner = data => post(url.ADD_AMOUNT_TO_OWNER, data);
export const getAllAmountToOwner = () => get(url.GET_ALL_AMOUNT);
export const deleteAmountToOwner = data => del(url.DELETE_AMOUNT_DATA, data);
export const EditAmountToOwner = data => put(url.EDIT_AMOUNT_TO_OWNER, data);
// All Rooms Sales
export const getRoomSalesDetails = (dbName, hid, startDate, endDate) =>  get(`${url.GET_ROOM_SALES_BASED_ON_DATE}?db=${dbName}&id=${hid}&fromDate=${startDate}&toDate=${endDate}`);
export const getRoomSaleTypeWiseAmount = (dbName, hid, startDate, endDate) =>  get(`${url.GET_ROOM_SALES_TYPE_WISE_AMOUNT}?db=${dbName}&id=${hid}&fromDate=${startDate}&toDate=${endDate}`);

// Activity Logs
export const get_Activity_logs = (formattedFromDate, formattedToDate) => get(`${url.GET_ACTIVITY_LOGS}?fromDate=${formattedFromDate}&toDate=${formattedToDate}`);
export const logoutlogApiRequest = data => post(url.POST_LOGOUT_LOGS, data)
// unused
export const testCategory = data => post(url.TEST_CATEGORY, data)
export const categoryList = companyId => get(`${url.GET_CATEGORY_LIST}/${companyId}`)
export const updateTestCategory = data => post(url.UPDATE_CATEGORY, data)
export const deleteTestCategory = data => post(url.DELETE_TESTCATEGORY, data)
// inventory stock
export const createInventory = data => post(url.CREATE_INVENTORY, data)
export const updateInventory = data => post(url.UPDATE_INVENTORY, data)
export const getInventory = (hid, dbName) => get(`${url.GET_INVENTORY}?db=${dbName}&id=${hid}`)
export const deleteInventory = (data, dbName) => del(`${url.DELETE_INVENTORY}?db=${dbName}&id=${data.id}`)
//settings
export const getHotelById = (hid) => get(`${url.GET_HOTEL}?id=${hid}`)
export const updateSettings = (dbName, hid, data) => post(`${url.UPDATE_SETTINGS}?db=${dbName}&id=${hid}`, data)
// Inventory Sales
export const createInventorySale = data => post(url.CREATE_INVENTORY_SALE, data)
export const updateInventorySale = data => post(url.UPDATE_INVENTORY_SALE, data)
export const post_service_inventory = data => post(url.SAVE_SERVICE_INVENTORY, data)
export const getInventorySales = (hid,  formattedFromDate, formattedToDate,dbName) => get(`${url.GET_INVENTORY_SALE}?db=${dbName}&fromDate=${formattedFromDate}&toDate=${formattedToDate}&id=${hid}`)
export const getSalesDetails = (dbName,bookingId ,hid) => get(`${url.GET_SALES_DETAILS}?db=${dbName}&BookingId=${bookingId}&id=${hid}`)
export const deleteInventorySale = (data, dbName) => del(`${url.DELETE_INVENTORY_SALE}?db=${dbName}&id=${data.id}`)
// income petty cash
export const createPettyCash = data => post(url.CREATE_PETTY_CASH, data)
export const getPettyCash = (hotelId, formattedFromDate, formattedToDate,dbName) => get(`${url.GET_PETTY_CASH}?hotelId=${hotelId}&fromDate=${formattedFromDate}&toDate=${formattedToDate}&dbName=${dbName}`)
// OverAll PettyCash Report
export const getAllPettyCash = (dbName,formattedFromDate, formattedToDate) => get(`${url.GET_ALL_PETTYCASH}?db=${dbName}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`)
export const getOverAllPettyCashReport  = (hotelId, formattedFromDate, formattedToDate,dbName) => get(`${url.GET_OVERALL_PETTY_CASH_REPORT}?hotelId=${hotelId}&fromDate=${formattedFromDate}&toDate=${formattedToDate}&dbName=${dbName}`)
// Reservation
export const CreateReservation = data => post(url.CREATE_RESERVATION, data)
export const getRoomWithoutCheckin = (hid, dbName, fromDate , toDate) => get(`${url.GET_ALL_ROOMS_NOT_CHECKIN}?db=${dbName}&id=${hid}&fromDate=${fromDate}&toDate=${toDate}`)
// export const getReservation = (hotelId, formattedFromDate, formattedToDate,dbName) => get(`${url.GET_RESERVATION}?hotelId=${hotelId}&fromDate=${formattedFromDate}&toDate=${formattedToDate}&dbName=${dbName}`)
// BOOKING Dashboard
export const getAllRoomCheckinDetails = (hid, dbName) => get(`${url.GET_ALL_ROOMS_CHECKIN_DETAILS}?db=${dbName}&id=${hid}`)
export const getAllRoomTypeDetails = (dbName) => get(`${url.GET_ALL_ROOMTYPE_DETAILS}?db=${dbName}`)
export const getAllRoomTypes = (dbName) => get(`${url.GET_ALL_ROOMTYPE}?db=${dbName}`)
//Booking CheckinForm
export const add_booking_checkin = data => post2(url.ADD_BOOKING_CHECK_IN, data)
export const getRoomsDetils = (hid, dbName, roomId) => get(`${url.GET_ROOMS_DETAILS}?db=${dbName}&id=${hid}&roomId=${roomId}`)
export const getRoomType = (hid, dbName,roomId) => get(`${url.GET_ROOMS_TYPES}?db=${dbName}&id=${hid}&roomId=${roomId}`)
export const getWithoutCheckinRooms = (hid, dbName) => get(`${url.GET_WITHOUT_CHECKIN_ROOMS}?db=${dbName}&id=${hid}`)
export const add_Block = data => post(url.ADD_BLOCK, data)
export const put_Block = data => post(url.PUT_BLOCK, data)
export const GetMobilenoDetails = (hid, dbName,value) => get(`${url.GET_MOBILENO_DETAILS}?db=${dbName}&id=${hid}&mobileNo=${value}`)
export const GetguestDetails = (hid, dbName,mobileNo) => get(`${url.GUESTMOBILENO_DETAILS}?db=${dbName}&id=${hid}&mobileNo=${mobileNo}`)


// Booking CHECKOUT FORM
export const getCheckoutDetails = (hid, dbName, bookingId) => get(`${url.GET_CHECKOUT_DETAILS}?db=${dbName}&id=${hid}&bookingId=${bookingId}`)
export const checkOutGuest = (hid, dbName, data, bookingId) => post(`${url.CHECKOUT_GUEST}?db=${dbName}&id=${hid}&bookingId=${bookingId}`, data)
export const getCheckoutServiceDetails = (hid, dbName, bookingId) => get(`${url.CHECKOUT_SERVICE}?db=${dbName}&id=${hid}&bookingId=${bookingId}`)
// Booking ADVANCE FORM
export const getAdvanceById = (hid, dbName, bookingId) => get(`${url.GET_ADVANCE_BY_ID}?db=${dbName}&id=${hid}&bookingId=${bookingId}`)
export const post_Advance_Details = data => post(url.POST_ADVANCE, data)
export const getGRCNoById = (hid, dbName, bookingId) => get(`${url.GET_GRC_NO_BY_ID}?dbName=${dbName}&id=${hid}&bookingId=${bookingId}`)
export const getAdvanceDetails = (hid, dbName, roomId) => get(`${url.GET_ADVANCE_DETAILS}?db=${dbName}&id=${hid}&roomId=${roomId}`)
//  Booking EXTRA GUEST FORM
export const getExtraGuestById = (hid, dbName, bookingId) => get(`${url.GET_EXTRA_GUEST_BY_ID}?dbName=${dbName}&id=${hid}&bookingId=${bookingId}`)
export const post_ExtraGuest_Details = data => post(url.POST_EXTRA_GUEST, data)
// RESERVATION
export const getAllReservation = (hid, dbName) => get(`${url.GET_ALL_RESERVATION}?db=${dbName}&id=${hid}`)
export const getReservationNotify = (hid, dbName) => get(`${url.GET_RESERVATION_NOTIFY}?db=${dbName}&id=${hid}`)
export const cancelReservation = (hid, dbName, data) => del(`${url.CANCEL_RESERVATION}?db=${dbName}&id=${hid}`, data)
//Shift Room APi
export const getGuestDetils = (hid, dbName, bookingId) => get(`${url.GET_GUEST_DETAILS}?db=${dbName}&id=${hid}&bookingId=${bookingId}`)
export const getWithoutCheckinRoomsAndType = (hid, dbName) => get(`${url.GET_WITHOUT_CHECKIN_ROOMS_TYPES}?db=${dbName}&id=${hid}`)
export const post_ChangeRoomData = data => post(url.ADD_CHANGE_ROOM, data)
export const getSameRoomsAndType = (hid, dbName,roomId) => get(`${url.GET_SAME_ROOMS_TYPES}?db=${dbName}&id=${hid}&roomId=${roomId}`)
export const getRoomServicedetails= (hid,dbName, bookingId) =>  get(`${url.ROOM_SERVICE_GET}?id=${hid}&db=${dbName}&bookingId=${bookingId}`)

// Blocks
export const getBlocksForSpecificRoomId = (hid, roomId, dbName, bookingId) => get(`${url.GET_BLOCKS_USING_BOOKINGID}?db=${dbName}&id=${hid}&bookingId=${bookingId}&roomId=${roomId}`)

// RoomStatus
export const getAllRoomStatus = (dbName) => get(`${url.GET_ALL_ROOM_STATUS}?db=${dbName}`)
//Update cleaning
export const update_cleaning = data => post(url.UPDATE_CLEANING, data)
export const update_cleaned = data => put(url.UPDATE_CLEANED, data)
export const getUpdateRecord = (hid, dbName, updateBookingId) => get(`${url.GET_CLEANING}?dbName=${dbName}&id=${hid}&updateBookingId=${updateBookingId}`)
//History
export const get_record = (dbName,servicePerson, FromDate, ToDate) =>  get(`${url.GET_RECORD_MAINTENANCE}?db=${dbName}&servicePersonId=${servicePerson}&fromDate=${FromDate}&toDate=${ToDate}`)
export const get_record_history = (hid , dbName, bookingId) =>  get(`${url.GET_RECORD_HISTORY}?db=${dbName}&id=${hid}&bookingId=${bookingId}`)
export const get_shiftRoom_history = (hid , dbName, bookingId) =>  get(`${url.GET_SHIFTROOM_HISTORY}?db=${dbName}&id=${hid}&bookingId=${bookingId}`)
export const getRoomServiceData = (date , dbName, bookingId) =>  get(`${url.GET_ROOM_SERVICE_DATE}?date=${date}&db=${dbName}&bookingId=${bookingId}`)
export const getAdvanceData = (date , dbName, bookingId) =>  get(`${url.GET_ADVANCE_DATA}?date=${date}&db=${dbName}&bookingId=${bookingId}`)
export const getGuestChargeData = (date , dbName, bookingId) =>  get(`${url.GET_GUEST_CHARGE}?date=${date}&db=${dbName}&bookingId=${bookingId}`)


//Convert Reservation 
export const getReservationRecord = (dbName, reservationData) =>  get(`${url.GET_RESERVATION_RECORD}?db=${dbName}&reservationData=${reservationData}`)
export const getRoomNumbers = (dbName, reservationData) =>  get(`${url.GET_ROOM_NUMBERS}?&dbName=${dbName}&reservationData=${reservationData}`)
export const getAvailableRooms = (dbName, reservationData , checkInDate , checkOutDate) =>  get(`${url.GET_AVAILABLE_ROOM_CHECKIN}?&dbName=${dbName}&reservationData=${reservationData}&checkInDate=${checkInDate}&checkOutDate=${checkOutDate}`)
export const convert_reservation_checkIn = data => post2(url.CONVERT_RESERVATION_CHECK_IN, data)

//Print
export const addPrintConfig = (data, dbName) => post2(`${url.ADD_PRINT_STYLE}?dbName=${dbName}`, data)
export const getPrintConfig = (dbName) => get(`${url.GET_PRINT_CONFIG}?dbName=${dbName}`)
export const getSign = (hid,dbName) => get(`${url.GET_SIGN}?hotelId=${hid}&dbName=${dbName}`)

//DashBoard
export const getRoomstatusCount = (dbName) => get(`${url.GET_ROOM_STATUS_DETAILS}?db=${dbName}`)
export const getCheckinOutCount = (dbName) => get(`${url.GET_CHECKIN_OUT_COUNT}?db=${dbName}`)
export const getReservedRoomCount = (dbName) => get(`${url.GET_RESERVED_ROOM_COUNT}?db=${dbName}`)
export const getNumberOfGuestCount = (dbName) => get(`${url.GET_NUMBER_OFGUEST_COUNT}?db=${dbName}`)
export const getWeeklyDayPayementAmount = (dbName) => get(`${url.GET_WEEKLY_DAY_AMOUNT}?db=${dbName}`)
export const getCashInHandAmount = (dbName) => get(`${url.GET_CASHIN_HAND_AMOUNT}?db=${dbName}`)
export const getReceivingAndRefundAmount = (dbName) => get(`${url.GET_RECEIVING_REFUND_AMOUNT}?db=${dbName}`)

//Income and Expense
export const getIncomeExpensePaymentTypewise = (hid,dbName, formattedFromDate, formattedToDate) => get(`${url.GET_INCOME_EXPENSE_PAYMENT_TYPEWISE}?hotelId=${hid}&dbName=${dbName}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`)

//Reports
export const getBalanceReport = (dbName, formattedFromDate, formattedToDate) => get(`${url.GET_BALANCE_REPORT}?dbName=${dbName}&fromDate=${formattedFromDate}&toDate=${formattedToDate}`)
export const getGuestAndInventoryDetails = (dbName) => get(`${url.GET_GUEST_INVENTORYSTOCK}?dbName=${dbName}`)

