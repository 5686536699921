import React, { useEffect, useState } from "react";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
import { getWeeklyDayPayementAmount } from "../../middleware/backent_helper";

const generateLast7Days = () => {
  const days = [];
  const today = new Date();

  for (let i = 6; i >= 0; i--) {
    const date = new Date();
    date.setDate(today.getDate() - i);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const formattedDate = `${day} ${month}`;
    const isoDate = date.toISOString().split("T")[0];

    days.push({ label: formattedDate, isoDate });
  }

  return days;
};

const DayWiseChart = () => {
  const [dbName] = useState(localStorage.getItem("dbName"));
  const [labels, setLabels] = useState([]);
  const [DaywisePayement, setPayement] = useState([]);
  const [dataValues, setDataValues] = useState([]);

  useEffect(() => {
    const last7Days = generateLast7Days();
    const formattedLabels = last7Days.map((day) => day.label);
    const isoDates = last7Days.map((day) => day.isoDate);

    setLabels(formattedLabels); // Update chart labels

    getWeeklyDayPayementAmount(dbName)
      .then(response => {
        setPayement(response.data.data);

        // Map each ISO date to the corresponding daily cash total
        const dailyTotals = isoDates.map((isoDate) => {
          const matchedData = response.data.data.find(
            (item) => item.TransactionDate === isoDate
          );
          return matchedData ? parseFloat(matchedData.DailyCashTotal) : 0;
        });

        setDataValues(dailyTotals); // Set the chart data
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, [dbName]);

  const dayWiseData = {
    labels,
    datasets: [
      {
        label: "Cash in Hand (Day-wise)",
        data: dataValues,
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            const value = context.raw;
            return `Amount: ₹${value}`;
          },
        },
      },
    },
  };

  return (
    <div className="bg-white p-3 rounded shadow-sm w-100 h-100">
      <h4>Day-Wise Cash in Hand</h4>
      <Bar className="mt-5" data={dayWiseData} options={options} />
    </div>
  );
};

export default DayWiseChart;
