import React, { useEffect, useState, useRef, useMemo } from "react";
import {

    Col,
    Container,
    Row,

} from "reactstrap";
import Select from "react-select"
import TableContainer from "../../../components/Common/TableContainer";
import withRouter from "../../../components/Common/withRouter";
import { Description, InDate, CreditCard, Online, Cash, Expenses, Remarks } from "./indexCol";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { getHotelById, getIncomeExpensePaymentTypewise } from "../../../middleware/backent_helper";
import DatePicker from "react-datepicker";

const IncomeAndExpenseReport = props => {
    //meta title
    document.title = "Income & Expenses | Income & Expenses ";

    const [transactionDate, setTransactionDate] = useState(new Date());
    const [datePickerOpen, setDatePickerOpen] = useState(false);

    const [hid, setHid] = useState(() => {
        const storedHotelId = localStorage.getItem('hotelId')
        return storedHotelId ? storedHotelId.replace(/"/g, '') : '';
    });
    const [dbName, setDbName] = useState(
        localStorage.getItem('dbName')
    );

    const [IncomeExpensesData1, setIncomeExpensePaymentTypewiseList] = useState([]);
    const [hotelUser, setHotel] = useState([]);
    const [FinalTotalIncomeandExpense, setSummaryData] = useState([]);
    const [TotalCardAmount, setTotalCard] = useState([]);
    const [TotalCashAmount, setTotalCash] = useState([]);
    const [TotalOnlineAmount, setTotalOnline] = useState([]);
    const [TotalDebitAmount, setTotalDebit] = useState([]);
    const datePickerRef = useRef(null);
    const today = new Date();
    const [selectedDates, setSelectedDates] = useState([
        {
            startDate: today,
            endDate: today,
            key: "selection",
        },
    ]);
    const formattedDateRange = `${format(selectedDates[0].startDate, "dd-MM-yyyy")} / ${format(selectedDates[0].endDate, "dd-MM-yyyy")}`;

    const columns = useMemo(() => [
        {
            Header: "S.No",
            Cell: ({ row, rows }) => {
                return (
                    <div className="table-body">
                        {rows.length - row.index}
                    </div>
                );
            },
        },
        {
            Header: "Description",
            accessor: "description",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Description {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Date",
            accessor: "transactionDate",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <InDate {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Card",
            accessor: "card",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <CreditCard {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Online",
            accessor: "online",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Online {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Cash",
            accessor: "cash",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Cash {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Expenses",
            accessor: "debit",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Expenses {...cellProps} />
                    </div>
                );
            },
        },
        {
            Header: "Remarks",
            accessor: "remarks",
            filterable: true,
            Cell: cellProps => {
                return (
                    <div className="table-cell text-center">
                        <Remarks {...cellProps} />
                    </div>
                );
            },
        },
    ]);



    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const handleSearch = (startDate, endDate) => {
        if (!startDate || !endDate) return;

        const hotelId = hid.replace(/"/g, '');
        const formattedFromDate = formatDate(startDate);
        const formattedToDate = formatDate(endDate);

        getIncomeExpensePaymentTypewise(hotelId, dbName, formattedFromDate, formattedToDate)
            .then((res) => {
                const { summary, details } = res.data.data;
                setIncomeExpensePaymentTypewiseList(details);
                setSummaryData(summary);

                let card = 0;
                let online = 0;
                let cash = 0;
                let debit = 0;
                details.forEach(record => {
                    // Add card and online amounts
                    if (record.card) {
                        card += parseFloat(record.card) || 0;
                    }
                    if (record.online) {
                        online += parseFloat(record.online) || 0;
                    }
                    if (record.cash) {
                        cash += parseFloat(record.cash) || 0;
                    }
                    if (record.debit) {
                        debit += parseFloat(record.debit) || 0;
                    }
                });

                setTotalCard(card);
                setTotalOnline(online);
                setTotalCash(cash);
                setTotalDebit(debit);
            })
            .catch((err) => {
                console.error("Error fetching  data:", err);
            });
    };

    useEffect(() => {

        const defaultStartDate = new Date();
        const defaultEndDate = new Date();
        handleSearch(defaultStartDate, defaultEndDate);
    }, [hid, dbName]);

    useEffect(() => {
        getHotelById(hid)
            .then(response => {
                const hotelData = response.data.data;
                setHotel(hotelData)
            })
    }, []);

    const handleDateRangeChange = (ranges) => {
        setSelectedDates([ranges.selection]);
        setDatePickerOpen(false);
    };

    const handleInputClick = () => {
        setDatePickerOpen(true);
    };

    var node = useRef();
    const onPaginationPageChange = page => {
        if (
            node &&
            node.current &&
            node.current.props &&
            node.current.props.pagination &&
            node.current.props.pagination.options
        ) {
            node.current.props.pagination.options.onPageChange(page);
        }
    };



    const handleClickOutside = (event) => {
        if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
            setDatePickerOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <React.Fragment>

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Reports" breadcrumbItem="Income and Expenses Report" />
                    <div className="mb-3">
                        <Row>
                            <Col lg="6" xs="12" md="6">
                                <div className="input-group">
                                    <div className="input-group-text">
                                        <span className="input-group-prepand">From - To</span>
                                    </div>
                                    <input
                                        type="text"
                                        value={formattedDateRange}
                                        readOnly
                                        onClick={handleInputClick}
                                        placeholder="Select Date Range"
                                        className="form-control"
                                    />
                                    <label className="input-group-text" htmlFor="inputGroupFile01">
                                        <FontAwesomeIcon icon={faCalendarAlt} />
                                    </label>
                                </div>

                            </Col>
                            <Col lg="6" xs="6" md="4" className="mt-3 mt-md-0  text-md-left">
                                <button
                                    className="btn btn-success"
                                    onClick={() => handleSearch(selectedDates[0].startDate, selectedDates[0].endDate)}
                                >
                                    <span className="fas fa-search" /> Search
                                </button>
                            </Col>
                        </Row>
                        {datePickerOpen && (
                            <div ref={datePickerRef} className="date-range-picker-dropdown mt-1">
                                <DateRangePicker
                                    onChange={handleDateRangeChange}
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={2}
                                    ranges={selectedDates}
                                    direction="horizontal"
                                    className="date-range-picker-dropdown"

                                />
                            </div>
                        )}
                    </div>
                    <Row>
                        <Col lg="12">
                            <TableContainer
                                columns={columns}
                                data={IncomeExpensesData1}
                                isGlobalFilter={true}
                                hotel={hotelUser}
                                isAddIncomeAndExpensesReport={true}
                                totalCardAmount={TotalCardAmount}
                                totalOnlineAmount={TotalOnlineAmount}
                                totalCashAmount={TotalCashAmount}
                                totalDebitAmount={TotalDebitAmount}
                                customPageSize={10}
                                className="custom-header-css"
                            />
                        </Col>


                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default withRouter(IncomeAndExpenseReport);
